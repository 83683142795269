.contact-form{
     padding: 0 3rem 0 3rem;
     display: flex;
     height: 50vh;
     margin-top: 4rem;
  justify-content: center;
   padding-top: 2rem;
 }
 .awesome>span:nth-child(1){
     color: var(--yellow);
     font-size: 2.9rem;
     font-weight: 700;
     margin-left: 3rem;
 }
 .awesome>span:nth-child(3){
     color: var(--black);
     font-size: 2.2rem;
     font-weight: 600;
     margin-left: 3rem;

 }
 
 .c-right{
     display: flex;
     justify-content: center;
     position: relative;
     flex: 1;
   
 }
 
 .c-right>form
 {
     display: flex;
     flex-direction: column;
     gap: 2rem;
     align-items: center;
     
 }
 
 .c-right .useri{
     
     width: 20rem;
     height: 2rem;
     padding: 0.3em;
     outline: none;
     border: 2px solid var(--orange);
     border-radius: 12px;
     font-size: 16px;   
 }
 
 textarea{
     width: 20rem!important;
     min-height: 4rem;
 }
 .b-Send{
     color:var(--black);
    font-size: larger;
    font-weight: 600;
     padding: 1rem 4rem;
 }
 
 .c-blur1{
     top: 0rem;
     left: 8rem;
 }
 
 @media screen and (max-width: 780px) {
     .contact-form{
         padding: 0;
         flex-direction: column;
         gap: 4rem;
         height: 40rem;
     }
     .w-left{
         text-align: center;
         font-size: larger;
     }
     .c-right>.useri{
         width: 18rem;
     }
 
 }   