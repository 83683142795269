/* heading  */
.project-name > span:nth-child(1) {
  color: var(--black);
  font-size: 2rem;
  margin-top: 2rem;
  font-weight: bold;
  margin-bottom: 0!important;
 
}

.project-name > span:nth-child(2) {
  color: var(--orange);
  font-weight: bold;
  padding-bottom: 1rem;
  margin-top: 0!important;
  font-size: 3rem;
}
.project-name {

  display:flex;
  color: var(--black);
  margin: 10rem 0;
  align-items:center;
  flex-direction:column;
  justify-content:space-between

}

.project-section{
     display: flex;
     justify-content: space-between;
}


/* slider  */
.project-slider{
  -webkit-border-radius: 37px;
  border-radius: 37px;
 
  -webkit-box-shadow: 8px 8px 23px #0691a0, -8px -8px 23px #09a3a3;
  box-shadow: 8px 8px 23px #0691a0, -8px -8px 23px #23b3b3;
margin: 0 4rem;
  width:90vw;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 3rem 5rem;
  padding-top: 1rem;

}

.bb{
  border-radius: 10px;
  padding: 1rem 2rem;
  font-weight: 700;
}


.exp-b {
  padding: 1rem 2rem;
  margin-left: 3rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
  border: none;
  font-weight: 600;
  font-size: 14px;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 3rem;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.exp-b:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.exp-b:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@media screen and (max-width:780px){
  .project-slide{
    width:auto;
  }
}