.works {
  padding: 0 4rem ;
  display: flex;
height: 20rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.left-ser{
  display: flex;
margin-right: 10rem;
    flex-direction: column;
    position: relative;
  
}
.left-ser > :nth-child(1) {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}
.left-ser > :nth-child(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}
.left-ser > :nth-child(3) {
  color: var(--gray);
  font-size: 17px;
  font-weight: 400;
  margin-top: 1rem;
}

.l-left {
  display: flex;
  flex-direction: column;
  position: relative;
}

.blur {
  position: absolute;
  width: 50%;
  height: 14rem;
  border-radius: 50%;
  background: #f3eeb8;
  filter: blur(212px);
  z-index: -9;
  top: -18%;
  left: 26%;
  filter: blur(62px);
}
/* .w-works {
  position: relative;
} */
.main-circle {
  left: 8rem;
  position: relative;
  height: 23rem;
  width: 23rem;
  border-radius: 100%;
  box-shadow: var(--boxShadow);
  background-color: white;
  top: 2rem;
}
.main-circle > * {
  position: absolute;
}

.sec-circle:nth-child(1) {
  top: 0rem;
  left: 8.5rem;
}

.sec-circle:nth-child(2) {
  top: 18rem;
  left: 9rem;
}

.sec-circle:nth-child(3) {
  top: 9rem;
  left: 0rem;
}

.sec-circle:nth-child(4) {
  top: 9rem;
  left: 18rem;
}

.sec-circle:nth-child(5) {
  top: 9rem;
  left: 9rem;
}
.sec-circle {
  width: 4.6rem;
  height: 4.6rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border:3px solid rgb(200, 13, 238);
  border-radius: 100%;
  background-color:rgba(251, 255, 0, 0.178);
  box-shadow: var(--boxShadow);
}
.back-circle {
  position: relative;
  height: 12rem;
  width: 12rem;
  border-radius: 100%;
  z-index: -1;
}
.back-circle1 {
  position: relative;
  height: 11rem;
  width: 11rem;
  border-radius: 100%;
  z-index: -1;
}
.blueC {
  background-color: rgb(11, 250, 198);
  left: 19rem;
  top: -21rem;
}
.blueC1 {
  background-color: rgb(11, 250, 198);
  right: -8rem;
  top: -33rem;
  
}
.yellowC {
  background-color: rgb(147, 19, 221);
  left: 19.5rem;
  bottom: 22rem;
}
.yellowC2 {
  background-color: rgb(147, 19, 221);
  right: -8rem;
  top: -56.5rem;
}
@media screen and (max-width: 780px) {
  .works {
    display: flex;
    flex-direction: column;
    margin-top:35rem;
    gap: 4rem;
   height:50rem;
    align-items: center;
    justify-content: center;
  }
  .w-works{
    
    margin-right:15rem;
  }
  .left-ser{
    width: 100%;
    margin: 0 auto;
  }

}
