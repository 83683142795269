.h-wrapper {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
margin-right:3rem;
  margin-left: 3rem;
}
.h-left {
  flex: 1;
  align-items: center;
  display: flex;
  gap: 2rem;
}
.h-name {
  font-size: 1.5rem;
  font-weight: 700;
  
}
.h-right {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: large;
}
.h-list {
  flex: 10;
}
.h-list > ul {
  display: flex;
  gap: 2rem;
  margin-right: 3rem;
}
.h-list > ul > li:hover {
  cursor: pointer;
  color: var(--orange)!important
}
ul {
  list-style-type: none;
}
li>a{
  text-decoration: none;
  font-weight: 700;
  color:var(--black);
}
li>a:hover{
  color: var(--orange)!important;
}
.h-button {
  float: 1;
}


@media screen and (max-width: 680px){
  .h-list{
    display: none;
   
   
  }
  .toggler{
display: inline-block;
  }
}





 