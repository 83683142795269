.intro {
  display: flex;
  height: 70vh;
  margin-top: 4rem;
  gap: 1rem;
  margin-left: 3rem;
  margin-right: 3rem;
}
.intro-left {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
  
}

.i-name {
  display: flex;
  flex-direction: column;
}
.i-name > :nth-child(1) {
  color: var(--black);
  font-weight: bold;
  font-size: 3rem;
}
.i-name > :nth-child(2) {
  color: var(--orange);
  font-size: 3rem;
  font-weight: bold;
}
.i-name > :nth-child(3) {
  color: var(--orangeCard);
  font-size: 2.5rem;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: bold;
}
.i-name > :nth-child(4) {
  font-weight: 200;
  font-size: 17px;
  font-weight: 400;
  margin-top: 10px;
  color: var(--gray);
}

.intro-right {
  flex: 1;
  position: relative;
  
}

.intro-right>* {
  flex: 1;
  position: absolute;
  z-index: 1;

}

.intro-right>:nth-child(1){
     transform: scale(0.7);
     left: -15%;
     top: -10%;
}
.i-button {
  width: 9rem;
  height: 2rem;
 text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: white;
}

.social-icons{
  margin-top: 1.rem;
     
     display: flex;
    gap: 1rem;
}
.social-icons>*{
     transform: scale(0.6);
}
.social-icons>*:hover{
     cursor: pointer;
     transform: translateY(2px);
}
@media screen and (max-width:780px) {
  .intro{
    flex-direction: column;
    height:65rem;
    column-gap: 2rem;
    padding-left: 0rem;
    padding-right: 1rem;
   
  
  }
  .intro-right>img{
width: auto;

  }
 
}
