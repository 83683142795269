.blur {
  position: absolute;
  width: 44rem;
  height: 14rem;
  border-radius: 50%;
  background: #fdf0c4;
  filter: blur(60px);
  z-index: -9;
  top: -18%;
  left: 56%;
}

.services {
  padding: 0 3rem 0 3rem;
  display: flex;
  min-height: 70vh;
  align-items: flex-start;
  gap: 2rem;
  margin-top: 12rem;
  justify-content: space-between;
  margin-bottom: 10rem;
}
.left-service {
  display: flex;
  flex-direction: column;
  position: relative;
  width:50%;
 
}
.left-service > :nth-child(1) {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}
.left-service > :nth-child(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}
.left-service > :nth-child(3) {
  color: var(--gray);
  font-size: 17px;
  font-weight: 400;
  margin-top: 1rem;
}
.s-button {
  width: 12rem;
  height: 3rem;
  color: white;
  font-weight: 800;
  margin-top: 1.5rem;
}
.cards{
     position: absolute;
}

@media screen and (max-width: 780px) {
  .services {
   margin-top: 8rem;
    flex-direction: column;
    height: 90rem;
  }
  .left-service {
    flex-direction: column;
    gap: 1rem;
    width: auto;
  }
  .cardgroup{
    margin-top: 5rem;
       display: flex;
       flex-direction: column;
       gap: 3rem;
    width: auto;
    margin: 0,auto;
  }
  .cardgroup>*{
    position: static;
  }
}
