.toggle{
     display: flex;
     justify-content: space-between;
     border: 2px solid var(--orange);
     border-radius: 1rem;
     position: relative;
     padding: 3px;
     cursor: pointer
     ;

}
.toggle>*{
     width: 1.1rem;
     height: 1.1rem;
     color: var(--orange);
}
.toggle-btn{
     border-radius: 100%;
     background-color: var(--orange);
     position: relative;
}