.card {
  width: 13rem;
  height: 18rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 10px;
  padding-right:10px;
  padding-top: 15px;

  align-items: center;
  text-align: center;

  border-radius: 20px;
 
}
.card{
     -webkit-border-radius: 33px;
border-radius: 33px;
border: 3px solid rgb(200, 13, 238);
background: #7ee6d8;
-webkit-box-shadow: 10px 10px 10px #88e7db, -10px -10px 15px #c6ebeb;
box-shadow: 10px 10px 15px #19574e, -10px -10px 15px #e1eeee;

}
.card span:nth-child(3){
     color:rgb(200, 13, 238);
     font-size: 14px;
     font-weight: 500;
}
.card span:nth-child(2){
     font-weight: 600;
     color: var(--black);
     font-size: 18px;
     margin-bottom: 0;
}
.c-button{
background-color: rgb(252, 243, 231);
font-size:18px;
font-weight: 600;
font-family: sans-serif;
border-radius: 10px;
border: none;
color: rgba(36, 165, 240, 0.945);
text-align: center;
padding-top: 0.5rem;
padding: 0 1rem;
}
.c-button:hover{
     cursor: pointer;
     background-color: var(--yellow);
     color: var(--black);
}

@media screen and (max-width: 580px){
     .card{
          width: auto;
       margin-top: 2rem;
     }

     .card span:nth-child(3){
         
          font-size: 18px;
          font-weight: 500;
     }
     
   }