.project-detail {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-bottom: 2rem;
  margin: 3rem 2rem;
  text-align: center;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  background: #c4d2ff;
  -webkit-box-shadow: 8px 8px 16px #97a2c4, -8px -8px 16px #f1ffff;
  box-shadow: 8px 8px 16px #97a2c4, -8px -8px 16px #f1ffff;
}
.project:nth-child(1) {
  color: var(--black);
  font-size: x-large;
  text-shadow: 2px 1px;
}
.project > li {
  color: var(--black);
  font-size: large;
  text-align: left;
  margin: 0 3rem;
  text-shadow: 1px 1px;
}
.images {
  gap: 4rem;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 780px) {
  .images {
    flex-direction: column;
    gap: 5rem;
    margin: 0 auto;
  }
}
