.blog {
  -webkit-border-radius: 39px;
  border-radius: 30px;
  background: #d6eff4;
  -webkit-box-shadow: 14px 14px 25px #a7babe, -14px -14px 25px #ffffff;
  box-shadow: 14px 14px 25px #a7babe, -14px -14px 25px #ffffff;
  text-align: center;
  width: 30rem;
  height: 15rem;
  margin: 2rem auto;
  padding: 1rem 0;
}
h1 {
  color: var(--yellow);
  font-size: 2.5rem;
}
h3 {
  font-size: 2rem;
  color: var(--black);
}
