.about{
height: 30rem;
     margin: 4rem 1rem;
     margin-top: 7rem;

}
@media screen and (max-width:780px) {
     .about{
          display: flex;
          flex-direction: column;
          margin: 4rem 1rem;
          margin-bottom: 10rem;
          height: 50rem;
          margin-left: 0%;
     }
     
}