.footer {
   border-radius:100% 100% 0% 0%  ;
  margin: -0, 5rem, -3rem 5rem;
  width: 100%;
 background-color:#25C4EE;
}
.footer>p{
     font-size: 1.1rem;
     color:var(--black);
     text-align: center;
     margin-top: -10px;

     padding-bottom: 30px;
}
.f-content,.menu {
  position: relative;
  display: flex;
 
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 1.5rem,4rem;
}
.f-content>li>a,.menu>li>a{
text-decoration: none;
color: var(--black);
     font-size: 1.2rem;
     font-weight: 600;
     margin-top: -4rem;
     display: inline-block;
     margin-right: 1.5rem;
     transition: 0.4s;

}
.f-content>li>a:hover

{
    transform: translateY(-15px);
  
    
}
.menu>li>a:hover
{opacity: 0.7;
     transform: translateY(-8px);
     border-radius: 10px;
}



    




